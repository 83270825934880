<template>
  <div class="form-items">
    <div class="wrapper">
      <div class="head">
        <div class="title">보완 사유 입력</div>
        <textarea class="form-control border-focus-point font-sm" ref="textareaRef" v-model="state.reason" placeholder="보완 사유를 입력하세요."></textarea>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-purple btn-block" type="submit" @click="submit()">저장하기</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "modalArchiveApplicationItemReject";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});
    });

    const modalParams = store.getters.modalParams(component);
    const textareaRef = ref();

    const state = reactive({
      reason: "",
    });

    onMounted(() => {
      if (textareaRef.value) {
        textareaRef.value.focus();
      }
    });

    const submit = () => {
      if (!state.reason?.trim()) {
        return store.commit("setSwingMessage", "보완 사유를 입력해주세요.");
      }

      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {
            modal,
            params: {
              answerId: modalParams.answerId,
              reason: state.reason
            }
          });
        }
      });
    };

    return {component, state, textareaRef, onMounted, submit};
  }
});
</script>

<style lang="scss" scoped>
.form-items {
  > .wrapper {
    background: #fff;
    padding: $px25;
  }

  .head {
    position: relative;

    .title {
      font-size: $px18;
      margin-bottom: $px20;
    }

    select {
      font-size: $px15;
      width: 100%;
      height: $px40;
      padding: $px7;
      background-color: #fff;
      transition: none;
    }

    textarea {
      margin-top: $px10;
      width: 100%;
      height: $px120;
    }
  }

  .action {
    position: sticky;
    bottom: 0;

    .btn {
      padding: $px15;
      height: $px60;
      border-radius: 0;
      font-size: $px13;

      &.remove {
        background: $colorSecondary;
        border-color: $colorSecondary;
        color: #fff;

        &:hover {
          background: $colorSecondaryActive;
          border-color: $colorSecondaryActive;
        }
      }
    }
  }

  &.skeleton {
    .head .side select {
      @include skeleton;
    }

    .body > .row > div .form-group {
      > .side > label {
        @include skeleton;
      }

      input, select, textarea {
        @include skeleton;
      }
    }
  }

  @media(max-width: 991px) {
    .body > .row > div.items {
      padding-bottom: 1.5rem;
    }
  }
}
</style>